/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Row, Col, Button} from "react-bootstrap"
import Header from "./header_en"
import '../css/custom.css'
import { Container } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "../components/seo"

const Layout = ({children, seo}) => {

    const data = useStaticQuery(graphql`
     query SiteTitleQuery_en {
      allStrapiFooterSections (sort: {fields: Order, order: ASC}){
        edges {
          node {
            id
            Title
            Order
            footer_section_lines{
              id
              Href
              Title
              Order
            }
          }
        }
      }
      allStrapiFooters {
        edges {
          node {
            id
            Year
            Logo {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(quality:100, height: 35, placeholder: NONE)
                }
              }
            }
          footer_social_networks {
              id
              Href
              Logo {
                id
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality:100, placeholder: NONE)
                  }
                }
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
     }
   `)

    //   if (typeof window !== "undefined") {
    // // eslint-disable-next-line global-require


    //   require("smooth-scroll")('a[href*="#"]', {

    //   })



    //  }

    return (
        <>
            <Seo seo={seo}/>
            <Header siteTitle={data.site.siteMetadata?.title || `Title`} />


            <main>{children}</main>



            <hr style={{ opacity:"1", width:"100%", margin:"0px", borderWidth:"0px", height:"1px",backgroundColor:"#FFBE0B"}}></hr>

            <footer className="footer-box">


                {data.allStrapiFooters.edges.map(footer => (
                    <Container key={footer.node.id}>
                        <Row className='mt-0'>

                            <Col className='mt-0 pr-0'>
                                {footer.node.Logo.map( footerlogo=> (
                                    <GatsbyImage key={footerlogo.id} image={footerlogo.localFile.childImageSharp.gatsbyImageData} className="ml-0 shadow-box-example z-depth-5" alt=""/>
                                ))}
                                <ul className="pt-1">
                                    <li className="pl-0" style={{fontSize:"10px"}}> © {new Date().getFullYear()}
                                        {` `}
                                    </li>
                                </ul>

                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <strong>Get in touch</strong>
                                        <Row className="pt-1" >
                                            {footer.node.footer_social_networks.map(social=> (
                                                <Col key={social.id}  className="pt-1" xs={2} sm={2} md={3} lg={3} xl={2}>
                                                    <a  href={social.Href} target="_blank" rel="noopener noreferrer">
                                                        {social.Logo.map(sociallogo=> (
                                                            <GatsbyImage  key={sociallogo.id} image={sociallogo.localFile.childImageSharp.gatsbyImageData} alt=""/>
                                                        ))}
                                                    </a>

                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='footer-menu' xs={12} sm={12} md={3} lg={3} xl={3}>
                                <strong>Features</strong>
                                <ul  className="pt-1 mb-0">
                                    <li className="pl-0"><a className="footer" href="/en/features/cash-flow-monitoring/">Cash flow monitoring</a></li>
                                    <li className="pl-0"><a className="footer" href="/en/features/cash-flow-forcast/">Cash flow forcast</a></li>
                                    <li className="pl-0"><a className="footer" href="/en/features/collaborative-cash-management/">Collaborative cash management</a></li>
                                    <li className="pl-0"><a className="footer" href="/en/features/integrations/">Integrations</a></li>
                                </ul>
                            </Col>
                            <Col className='footer-menu' xs={12} sm={12} md={3} lg={3} xl={3}>
                                <strong>Legal</strong>
                                <ul  className="pt-1 mb-0">
                                    <li className="pl-0"><a className="footer" href="/en/legal-notice/">Legal Notice</a></li>
                                    <li className="pl-0"><a className="footer" href="/en/privacy-policy/">Privacy Policy</a></li>
                                    <li className="pl-0"><a className="footer" href="/en/terms-of-service/">Terms of Service</a></li>
                                </ul>
                            </Col>
                            <Col className='footer-menu' xs={12} sm={12} md={3} lg={3} xl={3}>
                                <strong>About</strong>
                                <ul  className="pt-1 mb-0">
                                    <li className="pl-0"><a className="footer" href="/en/contact/">Contact Us</a></li>
                                </ul>
                            </Col>
                            <Col className="pb-3" xs={12} sm={12} md={6} lg={12} xl={12}>
                                <Button variant="outline-primary" className="footer-button"  href="/en/contact/">Contact us</Button>
                            </Col>
                        </Row>
                    </Container>
                ))}

            </footer>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout

// export const data = graphql`  
// query footer {
//   strapiFooters {
//     Year
//     footer_sections {
//       Title
//     }
//     footer_social_networks {
//       Logo {
//         url
//       }
//     }
//   }
//   site {
//     siteMetadata {
//       title
//     }
//   }
// }
// `
